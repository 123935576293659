import React, { Component } from "react"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import CardSegment from "../../components/marketing/CardSegment"
import LeftContentCard from "../../components/marketing/LeftContentCard"
import RightContentCard from "../../components/marketing/RightContentCard"
import SubscribeAction from "../../components/marketing/SubscribeAction"
import Whatsapp from "../../components/marketing/Whatsapp"
import AboutBiodiesel from "../../images/about_biodiesel.webp"
import AboutMPOB from "../../images/about_mbop.webp"
import AboutNRE from "../../images/about_nre.webp"
import CustFactory from "../../images/cust_factory.webp"
import CustHouse from "../../images/cust_house.webp"
import CustRestaurant from "../../images/cust_restaurant.webp"
import DoorImage from "../../images/home_ss14.webp"
import StepCollect from "../../images/step_collect.webp"
import StepFilter from "../../images/step_filter.webp"
import StepLab from "../../images/step_lab.webp"
import { Language } from "../../store/reducers/utilsReducer"

interface Props {
  authStore: any
  updatePromotionCode: (promoCode: string) => void
}

const workPosts = [
  {
    title: "Langkah 1 : Koleksi",
    desc:
      "Perkhidmatan pintu ke pintu disediakan untuk mengumpulkan minyak masak terpakai anda dari kawasan kediaman / kilang / restoran. Beritahu kami dengan membuat temu janji di Papan Pemuka Aplikasi Arus Oil",
    img: StepCollect,
  },
  {
    title: "Langkah 2 : Penapisan",
    desc:
      "Minyak masak terpakai yang dikumpulkan dari anda akan disaring dari sedimen. Sedimen yang biasa dijumpai dalam proses ini adalah serpihan makanan",
    img: StepFilter,
  },
  {
    title: "Langkah 3 : Ujian Makmal",
    desc:
      "Minyak masak terpakai anda akan dieksport ke kilang rawatan untuk ditukar menjadi bio-diesel (Digunakan untuk menggerakkan kendaraan / mesin industri)",
    img: StepLab,
  },
]

const customerSegmentPost = [
  {
    title: "Isi Rumah Keluarga",
    desc:
      "Kami menyediakan perkhidmatan pengumpulan dari pintu ke pintu untuk mengumpulkan minyak masak terpakai anda dengan berat minimum 5kg.",
    img: CustHouse,
  },
  {
    title: "Restoran / Hotel ",
    desc:
      "Kami mengumpulkan minyak masak terpakai dari lokasi restoran atau hotel anda. Kadar pembelian yang lebih baik akan diberikan bergantung pada jumlah minyak masak terpakai. Tong minyak untuk menyimpan minyak masak terpakai mungkin disediakan jika diperlukan ",
    img: CustRestaurant,
  },
  {
    title: "Kilang Makanan",
    desc:
      "Kami mengumpulkan minyak masak terpakai dari lokasi kilang makanan anda. Kadar pembelian yang lebih baik akan diberikan bergantung pada jumlah minyak masak terpakai.",
    img: CustFactory,
  },
]

class AboutUsPage extends Component<Props> {
  state = {
    visible: false,
    loading: false,
  }

  render() {
    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - Tentang Kami"
          description="Arus Oil bermula sebagai projek perintis di SS14, Subang, Klang Valley, Malaysia. Kami mengumpulkan minyak masak terpakai di Selangor, Negeri Sembilan, Pulau Pinang dan W.P. Kuala Lumpur dengan aktif."
        />
        <Header page="Tentang Kami" />
        <LeftContentCard
          image={DoorImage}
          title="Tentang Kami - Arus Oil"
          content={
            "Arus Oil (MyProtech Sdn. Bhd.) mula sebagai projek perintis untuk kelas menengah dalam komuniti SS14. Sejak tahun 2017, penduduk SS14 telah membuang minyak masak terpakai (UCO) mereka di tong koleksi di taman berdekatan. \n\n Projek ini dilaksanakan dengan kerjasama Kawasan Rukun Tetangga (KRT). Tinjauan menunjukkan bahawa kebanyakan isi rumah keluarga di SS14 sering memasak. Oleh itu, lahirlah idea minyak masak terpakai yang dikitar semula. Daripada membuang minyak masak terpakai anda, anda dapat memperoleh pulangan tunai dengan menjualnya."
          }
        />
        <RightContentCard
          image={AboutNRE}
          title="Visi Kami"
          content={
            "Dari tahun 2017 hingga hari ini, Arus Oil telah mengumpulkan kira-kira 250 metrik tan minyak masak terpakai. Sebab kita meneruskan usaha kitar semula sehingga hari ini adalah kerana kekurangan tenaga tidak boleh diperbaharui. 72% penggunaan dunia kita dikuasakan oleh bahan bakar fosil (Tidak boleh diperbaharui). Petroleum / minyak diperkirakan akan habis pada tahun 2052. \n\n Kami yakin dengan mengitar semula minyak masak terpakai dapat membantu melambatkan penipisan tenaga tidak boleh diperbaharui. Ini kerana semua minyak masak terpakai yang dikumpulkan akan ditukar menjadi bio-diesel yang digunakan untuk menggerakkan kendaraan atau mesin industri. \n\n Arus Oil aktif mengumpulkan minyak masak terpakai di Lembah Klang (Selangor & Kuala Lumpur - Malaysia). "
          }
        />
        <CardSegment types={workPosts} title="Bagaimana Kita Beroperasi" />
        <CardSegment types={customerSegmentPost} title="Segmen Pelanggan" />
        <LeftContentCard
          image={AboutBiodiesel}
          title="Apa Yang Berlaku Pada Minyak Masak Terpakai Anda"
          content={
            "Minyak masak terpakai anda akan dieksport ke kilang rawatan Belanda & UK di mana ia akan ditukar menjadi kelas Biodiesel ASTM B-20. Terdapat beberapa jenis biodiesel yang dipisahkan\n\n - Gred B-5 (Digunakan dalam kenderaan diesel tugas ringan)\n\n- Gred B-20 (Digunakan dalam kenderaan diesel atau generator)\n\n- Gred B-100 (Kenderaan berat misalnya. Kereta api & kapal terbang)"
          }
        />
        <RightContentCard
          image={AboutMPOB}
          contain={true}
          title="Pengendali Berlesen - Arus Oil​"
          content={
            "Lesen MPOB diperlukan untuk mengumpulkan / membeli minyak masak terpakai. Minyak masak terpakai (Kelapa Sawit) dikelaskan sebagai minyak kelapa sawit (SPO) di bawah Peraturan Perlesenan Lembaga Minyak Sawit Malaysia 2005.\n\n Arus Oil adalah pengendali berlesen di bawah MPOB dengan nombor lesen kami sebagai 619709017000. Beroperasi dengan lesen MPOB memastikan semua minyak masak terpakai yang dikumpulkan ditukar kepada biodiesel dan bukan untuk penggunaan haram yang akan membahayakan masyarakat dan alam sekitar."
          }
        />
        <SubscribeAction language={Language.BM} />
        <Footer />
      </div>
    )
  }
}

export default AboutUsPage
